.overlay_panel {
	position: fixed;
	top: 0;
	left: 100%;
	width: 100%;
	background-color: white;
	z-index: 10001;
	overflow-y: auto;
}

.overlay_panel_container {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	background-color: rgba(0, 0, 0, 0);
	z-index: 10000;
}

.op_close_btn {
	margin: 10px;
	text-align: left;
	border-bottom: 1px solid chocolate;
	padding-bottom: 2.5em;
}

.op_close_btn button {
	min-width: 100px;
	margin: 0 5px;
}

.op_title {
	color: black;
	width: 100%;
	text-align: center;
	font-size: 20px;
	font-family: 'Adobe Garamond Pro';
	font-style: italic;
}

.noscroll {
	position: fixed;
	overflow: hidden;
	width: 100%;
}

.reminder_heading {
	text-align: center;
	font-weight: bold;
	padding: 15px;
	background-color: chocolate;
	color: white;
}

span.reminder-btn-badge {
	display: none;
	z-index: 1;
	font-size: 0.7em !important;
}

span.reminder-btn-badge span {
	padding: 0 !important;
}

.tile-dialog {
}

.tile-dialog .modal-dialog {
	width: 100% !important;
	border-radius: 0 !important;
	margin: 50px auto;
}

.tile-dialog .modal-content {
	border-radius: 0 !important;
}

.tile-dialog .modal-body {
	padding: 0;
}

.tile-dialog .modal-header {
	border-top-left-radius: 0 !important;
	border-top-right-radius: 0 !important;
}

/* input:focus { 
	font-size: 16px!important
}
 */
