body {
	font-size: 5.3vw;
	font-family: helvetica;
	color: black;
	background-color: white;
	margin: 0 auto;
}

@media (min-width: 992px) {
	body {
		font-size: 1.2vw;
	}
}

.border-bottom {
	border-bottom: 1px solid darkgray;
}

.border-right {
	border-right: 1px solid darkgray !important;
}

.no-margin {
	margin: 0;
}

.no-padding {
	padding: 0;
}

.no-r-padding {
	padding-right: 0;
}

.no-l-padding {
	padding-left: 0;
}

.float-left {
	float: left;
}

.float-right {
	float: right;
}

.invalid-field-msg {
	color: indianred;
	font-weight: normal;
}

.align-right {
	text-align: right;
}

.align-center {
	text-align: center;
}

.image-upload-container {
	position: relative;
}

.image-upload-container input[type='file'] {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
}

#overlay-screen {
	display: none;
	background-color: rgba(0, 0, 0, 0);
	position: fixed;
	z-index: 5;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
}

#loading-overlay {
	display: none;
	background-color: rgba(0, 0, 0, 0.4);
	position: fixed;
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	z-index: 2000;
}

.input-sm {
	height: 2em;
	font-size: 0.9em;
}

#drive-authorize-btn {
	background-color: #e15f4f;
	color: white;
	font-size: 1.1em;
	padding: 10px;
	text-align: center;
	border-radius: 3px;
	cursor: pointer;
	display: none;
}

#drive-authorize-btn span.icon {
	background-color: rgba(255, 255, 255, 0.1);
}

#drive-authorize-container {
	position: fixed;
	top: 50%;
	transform: translateY(-50%);
	width: 100%;
}

#main-wrapper {
	/* margin-right: -12px; */
	/* display:none; */
	overflow-y: auto;
	overflow-x: hidden;
	box-shadow: 0px 0px 4px 3px darkgray;
	/* min-height: 400px; */
	height: calc(100% - 64px);
}

.wrapper {
	height: 100%;
}

#page-container {
	height: 100%;
}

.modal {
	z-index: 10040;
}
