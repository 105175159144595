.filtered-records {
	background-color: #d9edf7;
	padding: 0;
}
.tile .record-id {
	position: absolute;
	top: 15px;
	right: -4px;
	background-color: rgba(0, 0, 0, 0.5);
	padding-top: 2px;
	border-radius: 5px;
	min-width: 3em;
	text-align: center;
	z-index: 1;
}

.tile.detail .record-id {
	top: 30px;
}

.tile .record-id span {
	color: white;
}

.tile {
	border: 1px solid darkgray;
	padding: 5px;
	margin: 15px 5px;
	box-shadow: 1px 1px 5px 1px gray;
	position: relative;
	overflow: hidden;
	/* background-color: white; */
}

.tile:nth-child(even) {
	/* background-color: #f2dede; */
}

.highlight {
	font-weight: bold;
}

#dashboard-container {
	margin: 0 20px 0 10px;
}

#dashboard-container .chart-title {
	font-size: 1.1em;
	text-decoration: underline;
	font-weight: bold;
	padding-top: 20px;
}

#dashboard-container .chart-title span {
}

#op_print_config {
	background-color: chocolate;
}

#op_print_config .op_close_btn {
	border-bottom: 1px solid white;
}

#op_print_config .op_title {
	color: white;
}

#print-configs {
	margin: 10px;
}

#print-configs select {
	width: 100%;
}

#print-configs input {
	width: 100%;
	padding: 0;
	border: none;
	border-bottom: 1px solid white;
	background-color: chocolate;
	color: white;
}

#print-configs label {
	color: white;
	font-weight: normal;
}

#print-configs label input {
	width: inherit;
}

#print-configs input:focus {
	font-size: inherit !important;
	outline: none;
}

#print-configs .fields-section {
	margin-top: 10px;
}

#print-configs #print-preview {
	width: 100%;
	margin-top: 10px;
	height: 25em;
}

#print_records_btn {
	float: right;
	margin-right: 10px;
}

#export_records_btn {
	float: right;
}

#pending_transactions .tile {
	border: 1px solid darkgray;
	padding: 5px;
	margin: 5px;
	box-shadow: 1px 1px 5px darkgray;
	position: relative;
	overflow: hidden;
}

#pending_transactions .tile .title {
	font-weight: bold;
	width: 100%;
	padding: 5px;
	text-align: center;
}

#pending_transactions .tile .arguments {
	border-top: 1px solid darkgray;
	padding: 5px;
	width: 100%;
}

#pending_transactions .tile .arguments .field {
	display: inline-block;
	font-weight: bold;
}

#pending_transactions .tile .arguments .value {
	display: inline-block;
	margin-left: 5px;
}

#pending_transactions .quota_block {
	padding: 5px;
}

.select-tile {
	position: absolute;
	top: 10px;
	left: 5px;
	width: 15px;
	height: 15px;
}

.select-tile input {
	height: 18px;
	width: 18px;
}

.tile.detail.selected {
	border-width: 3px;
	border-color: chocolate;
}

/*.tile.detail.selected:before {
	position: absolute;
	content: " ";
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	background-color: black;
}*/

.tile-dialog .select-tile {
	display: none;
}

.custom-paper-size {
	display: none;
}

.btn-danger {
	float: left;
}
