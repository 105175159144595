.tt-dropdown-menu,
.gist {
	text-align: left;
}

.twitter-typeahead {
	width: 100%;
}

.typeahead,
.tt-query,
.tt-hint {
	height: 20px;
	padding: 4px 6px;
	font-size: 14px;
	line-height: 15px;
	border: 2px solid #ccc;
	-webkit-border-radius: 4px;
	-moz-border-radius: 4px;
	border-radius: 4px;
	outline: none;
}

.typeahead {
	background-color: #fff;
}

.typeahead:focus {
	border: 2px solid #0097cf;
}

.tt-query {
	-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	-moz-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
	box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
	color: #999;
	display: none;
}

.tt-dropdown-menu {
	margin-top: 0px;
	padding: 4px 0;
	background-color: #fff;
	border: 1px solid #ccc;
	border: 1px solid rgba(0, 0, 0, 0.2);
	-webkit-border-radius: 0px;
	-moz-border-radius: 0px;
	border-radius: 0px;
	-webkit-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	-moz-box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
	max-height: 150px;
	overflow-y: auto;
	width: 250px;
}

.tt-suggestion {
	padding: 2px 10px;
	font-size: 14px;
	line-height: 18px;
	border-bottom: 1px solid lightgray;
}

.tt-suggestion.tt-cursor {
	color: #fff;
	background-color: #0097cf;
}

.tt-suggestion p {
	margin: 0;
}

.gist {
	font-size: 14px;
}

.empty-message {
	padding: 5px 10px;
	text-align: center;
}
