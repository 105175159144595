#bottom-notification-container {
	position: fixed;
	bottom: 30px;
	left: 0;
	width: 100%;
	z-index: 100000;
}

#bottom-notification-container #bottom-notification-msg {
	width: 80%;
	margin: auto;
	text-align: center;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 25px;
	font-size: 18px;
	text-transform: capitalize;
	display: none;
}
