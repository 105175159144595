@charset "UTF-8";

#header-container {
	/* box-shadow: 0 5px 10px 1px gray; */
	position: relative;
	width: 100%;
}

::-webkit-input-placeholder {
	/* Chrome/Opera/Safari */
	color: lightgrey;
}

#header-container #header {
	background-color: darkcyan;
	color: white;
	text-align: center;
	padding: 5px;
}

#header-container #header span {
	text-transform: uppercase;
	font-size: 0.8em;
	word-spacing: 5px;
	letter-spacing: 3px;
}

#header-container #logo-info {
	padding: 5px 0;
	background-color: #fef4d1;
	display: none;
}

#header-container #logo-info #logo {
	width: 100%;
}

#header-container #logo-info #org-info {
	padding-left: 5px;
	padding-top: 10px;
}

#header-container #logo-info #org-name {
	font-size: 1.3em;
	font-weight: bold;
}

#header-container #logo-info #org-desc {
	font-size: 1em;
}

#header-container #header-subtitle {
	background-color: chocolate;
	color: white;
	text-align: center;
	padding: 5px;
}

#project-title {
}

#project-title span {
	color: yellow !important;
	text-align: center;
	text-transform: uppercase;
	letter-spacing: 1px !important;
	font-size: 0.7em !important;
	word-spacing: 1px !important;
}

#controls-header {
	background-color: chocolate;
	color: white;
	text-align: center;
	/*box-shadow: 0px 5px 10px 1px gray;*/
}

#controls-header select {
	color: black;
}

#controls-header .left-btn-grp {
}

#controls-header .right-btn-grp {
}

#search-section #search-text-input,
#find-section #find-text-input {
	background-color: rgba(210, 105, 30, 1);
	border: 0;
	text-align: center;
}

#quota_section {
	display: none;
}

#sorting-section {
	padding-top: 5px;
	display: none;
}

#sorting-section .icon {
	padding: 0;
}

#sorting-section .icon i {
	float: right;
}

#sorting-section .icon i.alt-order {
	display: none;
}

#sorting-section .text {
	padding: 0 0 0 5px;
	font-size: 0.9em;
}

#sorting-section .sort-option {
	padding: 0;
	text-align: left;
	cursor: pointer;
}

input:disabled {
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	border-bottom: 1px solid white;
}

#search-section input,
#find-section input {
	background-color: rgba(0, 0, 0, 0);
	border: 0;
	border-bottom: 1px solid white;
	color: white;
	width: 100%;
}

#search-section .input-daterange input {
	text-align: left;
}

#search-section input:focus,
#find-section input:focus {
	outline: none;
}

#controls-header button {
	padding: 3px 8px 0px 8px;
	margin-left: 8px;
	margin-right: 8px;
	margin-top: 15px;
}

#controls-header span {
	text-transform: uppercase;
	font-size: 0.7em;
	padding-top: 4px;
	display: inline-block;
}

select {
	font-size: 0.7em;
}

@media (min-width: 992px) {
	#controls-header span {
		font-size: 0.9em;
	}
	select {
		font-size: 1em !important;
	}
}

#selection-dropdown {
	text-align: left;
}

#selection-dropdown select {
	margin: 0px 0 0 0;
	width: 100%;
}

#record_count {
	text-align: left;
	color: white;
	font-size: 0.9em;
	padding-top: 2px;
	margin-top: 12px;
}

#search-section,
#find-section {
	position: relative;
}

#search-section .specific-search-btn,
#search-section .clear-search-btn {
	/*padding: 5px;*/
}

#search-section .specific-search-btn i,
#search-section .clear-search-btn i {
	display: inline-block;
	color: white;
	background-color: rgba(0, 0, 0, 0.5);
	border-radius: 5px;
	padding: 3px;
	margin-top: 5px;
	box-shadow: 0px 0px 5px darkgray;
}

#search-section .specific-search-section {
	display: none;
	margin: 0;
	padding: 5px;
}

#record_count span {
	margin-right: 5px;
}

.fix-header {
	position: fixed;
	top: 0;
	left: 0px;
	width: 100%;
	z-index: 11;
	box-shadow: 0px 5px 10px 1px gray;
}

#controls-header-placeholder {
	height: 0;
}

.progress {
	margin-bottom: 3px !important;
}

.navbar #search-section,
.navbar #find-section {
	display: block;
	padding-top: 12px;
}

#controls-header .navbar {
	margin-bottom: 0;
}

#controls-header {
	background-color: chocolate;
}

#controls-header a {
	color: lightgray;
	transition: all 0.5s ease;
	padding-left: 0;
	padding-right: 8px;
}

#controls-header .dropdown-menu {
	background-color: chocolate;
	overflow: auto;
	max-height: calc(100vh - 60px);
}

#controls-header .dropdown-menu a {
	padding-left: 15px;
}

#controls-header .dropdown-menu label {
	font-weight: normal;
	padding-left: 5px;
	cursor: pointer;
	color: lightgray;
}

#controls-header .dropdown-menu input[type='checkbox'] {
	margin-left: 15px;
	cursor: pointer;
	color: lightgray;
}

#controls-header .navbar-toggle span.icon-bar {
	display: block;
}

#controls-header button.navbar-toggle {
	padding: 9px 10px !important;
	margin-right: 15px;
}

.menu-links {
	padding-top: 4px;
	text-align: left;
}

.menu-links .menu-text {
	text-align: left;
}

.menu-links a:hover,
.menu-links a:focus {
	color: white !important;
	background: none;
	/*margin-top: -15px;*/
	text-shadow: 0 0px 5px white;
}

.menu-links .dropdown-menu > li > a:hover,
.menu-links .dropdown-menu > li > a:focus {
	color: white !important;
	background: none;
	/*margin-top: -15px;*/
	text-shadow: 0 0px 5px white;
}

@media (max-width: 991px) {
	.menu-links .glyphicon {
		font-size: 1.2em !important;
		padding: 0 10px !important;
	}

	.menu-links {
		padding-top: 5px !important;
		padding-left: 5px;
	}

	.menu-links a.btn {
		padding: 0 !important;
	}

	.navbar #search-section,
	.navbar #find-section {
		padding-top: 5px;
	}

	.total-records {
		padding-left: 0;
	}

	#record_count {
		float: left;
		width: 40%;
		text-align: left;
		font-size: 1.2em;
	}

	#record_count.compact {
		width: 100%;
	}

	#record_count span {
		margin-right: 0;
	}

	#selection-dropdown {
		float: right;
		width: 60%;
		padding-top: 4px;
	}

	#selection-dropdown select {
		width: 100%;
	}

	.navbar #search-section {
		padding-left: 0;
	}

	.search-anything,
	.menu-links {
		padding-right: 0;
	}
}

.find-anything {
	width: 20%;
	float: right;
}

.logout-btn {
	padding-right: 25px !important;
}

#sorting-element {
	display: none;
}

#searching-element {
	display: none;
}

.portal-btn {
	float: right;
	position: absolute;
	right: 0px;
	top: 10px;
}

#controls-header .offline-btn {
	display: none;
	position: absolute;
	right: -10%;
	color: crimson;
}

@media (max-width: 991px) {
	.portal-btn {
		right: -10px;
	}
}

#controls-header .navbar .menu-links a.logout-btn {
	display: inline-block;
	position: absolute;
	right: 0;
}

#controls-header .navbar .menu-links a.portal-btn {
	display: none;
}
